import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css';
import Portada_1 from '../imagenes/Portada_1.jpg';
import Portada_2 from '../imagenes/Portada_2.jpg';
import Portada_3 from '../imagenes/Portada_3.jpg';
import Portada_4 from '../imagenes/Portada_4.jpg';

const Carousel = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                <div className="carousel-slide">
                    <div className="overlay"></div>
                    <img src={Portada_1} alt="Slide 1" />
                    <div className="carousel-text">
                        <h1>CREANDO ESPACIOS PARA TU VIDA</h1>
                    </div>
                </div>
                <div className="carousel-slide">
                <div className="overlay"></div>
                    <img src={Portada_2} alt="Slide 2" />
                    <div className="carousel-text">
                        <h1>Donde el diseño y la innovación se encuentran</h1>
                    </div>
                </div>
                <div className="carousel-slide">
                <div className="overlay"></div>
                    <img src={Portada_3} alt="Slide 3" />
                    <div className="carousel-text">
                        <h1>REDEFINIENDO EL HORIZONTE URBANO</h1>
                    </div>
                </div>
                <div className="carousel-slide">
                <div className="overlay"></div>
                    <img src={Portada_4} alt="Slide 4" />
                    <div className="carousel-text">
                        <h1>Inspirando el futuro del desarrollo inmobiliario</h1>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Carousel;
