import React from 'react';
import './MissionVisionVideo.css';
import Inicio_1 from '../imagenes/Inicio_1.jpeg';
import Inicio_2 from '../imagenes/Inicio_2.jpeg';

const HomeInfo = () => {
    return (
        <div className="page-container">
            <section className="Inicio1">
                <img src={Inicio_1} alt="Inicio1" className="image-left" />
                <div className="text-right">
                    <h2>Cada proyecto una historia</h2>
                    <div className="paragraph">
                        Te damos la bienvenida a Caravi, donde cada proyecto es una historia única de diseño y funcionalidad. En Caravi, nos enorgullece crear espacios que van más allá de lo ordinario, combinando meticulosamente originalidad y excelencia para ofrecer experiencias de vida y trabajo excepcionales. Descubre cómo cada uno de nuestros proyectos redefine el estándar de excelencia en el sector inmobiliario, transformando sueños en realidad con cada detalle cuidadosamente pensado. Bienvenido a la excelencia, bienvenido a Caravi.
                    </div>
                </div>
            </section>
            <section className="Inicio2">
                <div className="text-left">
                    <h2>Vislumbra el futuro</h2>
                    <div className="paragraph">
                        Descubre el futuro de los espacios inmobiliarios con Caravi. Sumérgete en nuestra visión de innovación y excelencia, donde cada proyecto refleja nuestro compromiso con la calidad y el diseño excepcional. Explora nuestros valores fundamentales que guían cada paso de nuestro camino. Conoce cómo estamos estableciendo un nuevo estándar en desarrollo inmobiliario, redefiniendo lo que significa crear espacios que inspiran y perduran.
                    </div>
                </div>
                <img src={Inicio_2} alt="Visión" className="image-right" />
            </section>
            <section className="video-section">
                <iframe className='video-drive-inicio'
                    title='Bienvenido a Caravi'
                    src="https://www.youtube.com/embed/nbMqNEv06og"
                    width="640"
                    height="480"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
            </section>

        </div>
    );
};

export default HomeInfo;
