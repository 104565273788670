import Slider from 'react-infinite-logo-slider';
import './InfiniteSlider.css'; // Import your CSS file for styling
// import foto1 from "./slider_img/foto1.png";
// import foto2 from "./slider_img/foto2.png";

const InfiniteSlider = ({ children }) => {
    return (
        <Slider
            width="250px"
            duration={40}
            pauseOnHover={false}
            blurBorders={false}
            blurBoderColor={'#fff'}
            className="slider"
        >
            { children }
        </Slider>
    )
}

export default InfiniteSlider;
