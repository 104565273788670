import React from "react";
import './Portafolio.css';
import Slider from 'react-infinite-logo-slider';
// import { Gallery } from "../../Components/Gallery/Gallery";
import InfiniteSlider from "../../Components/InfiniteSlider";
import portfolio from "../../imagenes/Portfolio.jpeg"

const Portfolio = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="title">
        <div className="portfolio-image">
          <div className="overlay"></div>
          <img src={portfolio} alt="Slide 1" />
          <div className="carousel-text">
            <h1>Prepárate para ser parte de algo extraordinario</h1>
          </div>
        </div>

      </div>
      {/* <Gallery data={props.gallery} /> */}
      <InfiniteSlider>
        <Slider.Slide className="slider-slide">
          <p className="slider-text">PRÓXIMAMENTE</p>
        </Slider.Slide>
        <Slider.Slide className="slider-slide">
          <p className="slider-text">PRÓXIMAMENTE</p>
        </Slider.Slide>
      </InfiniteSlider>
    </div>
  );
};

export default Portfolio;