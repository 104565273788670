import React from "react";
import './GalerySection.scss'

const GallerySection = (props) => {
    return (
        <section id={props.name}>
            <div>
                <div className="text-center">
                    <h1>{props.title}</h1>
                    <div className="galeria">
                        {props.images
                            ? props.images.map((d, i) => (
                                <div
                                    className="galeria-item"
                                    key={`${d.title}-${i}`}
                                >
                                    <img src={d.path} alt={d.title} />
                                </div>
                            ))
                            : "Loading..."}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GallerySection;