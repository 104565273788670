import React from "react";

const ImageSideSection = (props) => {
    return (
        <section className={props.name}>
            {props.left ?
                <>
                    <img src={props.image_path} alt={props.title} className="image-right" />
                    <div className="text-center">
                        <h2>{props.title}</h2>
                        <p>{props.text}</p>
                    </div>
                </>
                :
                <>
                    <div className="text-center">
                        <h2>{props.title}</h2>
                        <p>{props.text}</p>
                    </div>
                    <img src={props.image_path} alt={props.title} className="image-left" />
                </>
            }

        </section>
    );
}

export default ImageSideSection