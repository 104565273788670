import React from 'react';
import MissionVisionVideo from '../../Components/MissionVisionVideo'; 
import './About_Us.css';
import MX_6 from '../../imagenes/Valores.jpg';

const AboutUs = () => {
    return (
        <div>
            <MissionVisionVideo />
            <section className="valores">
                <div className="text-left">
                    <h2>Valores</h2>
                    <ul className="values-list">
                        <li><strong>Excelencia:</strong> Buscamos la perfección y la calidad en todos nuestros proyectos y operaciones.</li>
                        <li><strong>Innovación:</strong> Nos comprometemos a ser líderes en el uso de tecnologías avanzadas y prácticas innovadoras en el desarrollo inmobiliario.</li>
                        <li><strong>Sostenibilidad:</strong> Promovemos la construcción y el desarrollo responsables, minimizando nuestro impacto ambiental y contribuyendo positivamente al entorno.</li>
                        <li><strong>Integridad:</strong> Actuamos con honestidad, transparencia y ética en todas nuestras relaciones y operaciones.</li>
                        <li><strong>Compromiso comunitario:</strong> Valoramos y apoyamos las comunidades locales donde operamos, buscando siempre contribuir de manera positiva a su desarrollo.</li>
                        <li><strong>Calidad y servicio al cliente:</strong> Nos esforzamos por ofrecer productos y servicios de la más alta calidad, centrados en superar las expectativas de nuestros clientes y usuarios finales.</li>
                    </ul>
                </div>
                <img src={MX_6} alt="Valores" className="image-right"/>
            </section>
            <section className="video-section">
                <iframe className='video-drive-ab'
                    src="https://www.youtube.com/embed/0z3NfnIW-18"
                    title='Introducción a Caravi'
                    width="640"
                    height="480"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
            </section>
        </div>
    );
};

export default AboutUs;
