import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTiktok,
  FaLinkedin,
  FaWhatsapp
} from "react-icons/fa";
// import { GrMail } from "react-icons/gr";

function Footer() {
  return (
    <footer className="footer">
      <div className="container_footer">
        <div className="footer-columns">
          <div className="footer-column">
            <div>
              <h2>Teléfono:</h2>
              <a href="tel:+5518173107">55 1817 3107</a>
            </div>
            <div>
              <h2>Email:</h2>
              <a href="mailto:contact@caraviresidence.com">
                contact@caraviresidence.com
              </a>
            </div>
            <div>
              <h2>Dirección:</h2>
              <p>
                <a href="https://maps.app.goo.gl/fmu8GN2uELVwLZp27" target="_blank" rel="noopener noreferrer">Av. P.° de la Reforma 284, Juárez, Cuauhtémoc, 06600, Ciudad de México, CDMX</a>
              </p>
            </div>
          </div>
          <div className="footer-column">
            <ul className="footer-menu">
              <li className="footer-menu-item">
                <a href="/privacy">Aviso de Privacidad</a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <ul className="footer-social-media">
            <li className="footer-social-media-item">
                <a href="https://wa.me/525518173107" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp />
                </a>
              </li>
              <li className="footer-social-media-item">
                <a href="https://www.linkedin.com/company/caravi-residence/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
              </li>
              <li className="footer-social-media-item">
                <a href="https://www.facebook.com/share/bUXoRMbFDkyfFTsT/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                  <FaFacebookSquare />
                </a>
              </li>
              <li className="footer-social-media-item">
                <a href="https://www.instagram.com/caraviresidence?igsh=MTN2aTlhYTRzbHY5bA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </a>
              </li>
              <li className="footer-social-media-item">
                <a href="https://www.tiktok.com/@caravi.residence?_t=8nJlkXrnfRm&_r=1" target="_blank" rel="noopener noreferrer">
                  <FaTiktok />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
