import React, { useState, useEffect } from 'react';
import logo from '../Caravi.png';
import ScrollToTopLink from '../Components/ScrollTopLink';
import './Header.css';

const Header = () => {
    const [isSolid, setIsSolid] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSolid(true);
            } else {
                setIsSolid(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`header ${isSolid ? 'solid' : ''}`}>
            <div className="header-space"></div>  {/* Left spacer */}
            <ScrollToTopLink to="/" className="logo">
                <img src={logo} alt="Logo" className="logo" />
            </ScrollToTopLink>
            <nav className="menu">
                <ul>
                    <li><ScrollToTopLink to="/" className={isSolid ? 'solid-menu-item' : ''}>Inicio</ScrollToTopLink></li>
                    <li><ScrollToTopLink to="/about_us" className={isSolid ? 'solid-menu-item' : ''}>Acerca De</ScrollToTopLink></li>
                    <li><ScrollToTopLink to="/portfolio" className={isSolid ? 'solid-menu-item' : ''}>Portfolio</ScrollToTopLink></li>
                    <li><ScrollToTopLink to="/contact" className={isSolid ? 'solid-menu-item' : ''}>Contacto</ScrollToTopLink></li>
                </ul>
            </nav>
            <div className="header-space"></div>  {/* Right spacer */}
        </header>
    );
};

export default Header;
