import React from 'react';
import './MissionVisionVideo.css';
import MX_4 from '../imagenes/Mision.jpg';
import MX_5 from '../imagenes/Vision.jpg';

const MissionVisionVideo = () => {
    return (
        <div className="page-container">
            <section className="mission">
                <div className="text-left">
                    <h2>Misión</h2>
                    <div className="paragraph">
                        Transformar el panorama inmobiliario mediante la creación de espacios que no solo satisfagan las necesidades actuales, sino que también inspiren y perduren en el tiempo. Nos comprometemos a alcanzar nuevos estándares de excelencia y diseño, integrando innovación y sostenibilidad en cada proyecto que emprendemos.
                    </div>
                    <div className="paragraph">
                        Guiados por nuestros valores de integridad, profesionalismo y compromiso con la comunidad, buscamos superar las expectativas de nuestros clientes, colaboradores y socios, contribuyendo de manera positiva al desarrollo urbano y social.
                    </div>
                    <div className="paragraph">
                        En Caravi, nuestro objetivo es dejar una huella significativa en el paisaje arquitectónico, ofreciendo soluciones innovadoras que mejoren la calidad de vida y fomenten un entorno urbano más inclusivo y vibrante.
                    </div>
                </div>
                <img src={MX_4} alt="Misión" className="image-right" />
            </section>

            <section className="vision">
                <img src={MX_5} alt="Visión" className="image-left" />
                <div className="text-right">
                    <h2>Visión</h2>
                    <div className="paragraph">
                        Liderar la transformación del sector inmobiliario al crear espacios innovadores y sostenibles que inspiren y enriquezcan la vida de las comunidades donde operamos.
                    </div>
                    <div className="paragraph">
                        Nos vemos como pioneros en el desarrollo de proyectos que no solo sean estéticamente impresionantes, sino también funcionales y respetuosos con el medio ambiente.
                    </div>
                    <div className="paragraph">
                        Buscamos ser reconocidos por nuestra capacidad para integrar tecnología de vanguardia, diseño arquitectónico excepcional y prácticas de construcción sostenible en cada uno de nuestros desarrollos.
                    </div>
                    <div className="paragraph">
                        Aspiramos a establecer nuevos estándares de calidad y excelencia en el mercado inmobiliario, superando las expectativas de nuestros clientes y generando valor a largo plazo para nuestros inversionistas y socios estratégicos.
                    </div>
                    <div className="paragraph">
                        En Caravi, nuestra visión es trascender como una marca líder que no solo construye, sino que también moldea y revitaliza paisajes urbanos, contribuyendo positivamente al desarrollo socioeconómico de las comunidades en las que estamos presentes.
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MissionVisionVideo;
