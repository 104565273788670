import React from 'react';
import './Proyecto.scss';
import ImageSideSection from '../../../Components/Image-side-Section/imageSideSection';
import ImageBackSection from '../../../Components/Image-back-Section/imageBackSection';
import GalerySection from '../../../Components/galery-section/GalerySection';
import TextSection from '../../../Components/text-section/TextSection';
import MX_4 from '../../../imagenes/Vision.jpg';
import MX_5 from '../../../imagenes/Mision.jpg';

const Proyecto = (props) => {
    return (
        <div className="page-container">
            <div className="container1">
                <div className="container">
                    <div id="portfolio" className="text-center">
                        <div className="section-title">
                            <h2>Proyecto</h2>
                            <p>
                                Commodo velit dolor irure velit laboris do nisi. Excepteur in ullamco nisi reprehenderit ex ex nostrud incididunt amet velit velit pariatur. Cillum eu esse est excepteur dolor sint ea dolore ullamco anim eu proident. Sint cillum occaecat ipsum consequat ad velit voluptate quis elit laboris dolore. Laboris laboris irure ut consequat.
                            </p>
                        </div>
                    </div>
                </div>
                <img src={MX_4} alt="Proyecto" className="big-image" />
            </div>
            <ImageBackSection
                name="Section1"
                title="Section 1"
                text="Mollit laboris laboris reprehenderit ea minim minim. Voluptate consequat incididunt amet adipisicing qui. Anim in labore aliquip sunt cupidatat incididunt quis proident minim."
            />
            <ImageSideSection
                name="Section2"
                title="Section 2"
                text="Fugiat sint in mollit eu cupidatat aute. Ut sunt aliquip aliquip dolor sint pariatur cillum duis fugiat. Duis deserunt proident aliqua dolor magna laboris veniam. Nulla eu aliquip sunt ullamco dolore ea sint do velit. Ullamco ad aliqua est cupidatat est proident aute aliqua ea amet elit."
                image_path={MX_4}
            />
            <ImageSideSection
                title="Section 3"
                text="Fugiat sint in mollit eu cupidatat aute. Ut sunt aliquip aliquip dolor sint pariatur cillum duis fugiat. Duis deserunt proident aliqua dolor magna laboris veniam. Nulla eu aliquip sunt ullamco dolore ea sint do velit. Ullamco ad aliqua est cupidatat est proident aute aliqua ea amet elit."
                image_path={MX_5}
                left={true}
            />
            <TextSection
                name="Section4"
                title="Section 4"
                text="Eiusmod sunt ut minim enim ipsum sunt cillum anim deserunt do quis cupidatat. Dolor non excepteur veniam ut. Occaecat nulla ullamco laboris veniam aliqua id dolor incididunt mollit et dolore. Consequat dolor nisi sit ipsum minim esse irure veniam officia qui laboris. Cupidatat anim ut nostrud cillum velit adipisicing culpa anim fugiat aute consequat proident id aute. Non ullamco amet dolor quis aute non pariatur mollit ad dolor esse eiusmod ex."
            />
            <GalerySection
                name="Section5"
                title="Section 5"
                images={[
                    {
                        path: MX_4,
                        title: "Galleria1"
                    },
                    {
                        path: MX_4,
                        title: "Galleria2"
                    },
                    {
                        path: MX_4,
                        title: "Galleria3"
                    },
                    {
                        path: MX_4,
                        title: "Galleria4"
                    },
                    {
                        path: MX_4,
                        title: "Galleria5"
                    },
                    {
                        path: MX_4,
                        title: "Galleria6"
                    },
                    {
                        path: MX_4,
                        title: "Galleria7"
                    },
                    {
                        path: MX_4,
                        title: "Galleria8"
                    },
                ]}
            />

            <section className="video-section">
                <iframe className='video-yt'
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/YqyYenoVADU"
                    title="YouTube video player"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </section>
        </div >
    );
};

export default Proyecto;
