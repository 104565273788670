import React from "react";

const ImageBackSection = (props) => {
    return (
        <section className={props.name}>
                <div class="overlay">
                    <div className="section-title">
                        <h1>{props.title}</h1>
                        <p>{props.text}</p>
                    </div>
                </div>
        </section>
    );
}

export default ImageBackSection